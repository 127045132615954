<template>
  <div>
    <v-row>
      <!-- basic -->
      <v-col cols="12">
        <v-card>
          <div class="w-full boxed-container">
            <div class="mr-6 mb-4 align-center">
              <v-row>
                <v-col sm="6" cols="12">
                  <v-card-title>Post Categories</v-card-title>
                </v-col>
                <v-col sm="4" class="pl-4 v-card__title" cols="8">
                  <v-text-field
                    rounded
                    dense
                    outlined
                    v-model="search"
                    @keyup.enter="init(1)"
                    :prepend-inner-icon="icons.mdiMagnify"
                    class="flex-grow-0 app-bar-search"
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col sm="2" class="text-right mt-3" cols="4">
                  <v-btn class="ml-5" @click="$router.push({ name: 'PostCategoryAdd' })" color="#56ca00" icon>
                    <v-icon>
                      {{ icons.mdiPlusCircleOutline }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Id</th>
                  <th class="text-center">Name</th>
                  <th class="text-center">Image</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in items" :key="i">
                  <td>{{ i + 1 }}</td>
                  <td class="text-center">
                    {{ item.name }}
                  </td>
                  <td class="justify-center d-flex">
                    <v-img max-width="100" contain :src="item.image"></v-img>
                  </td>
                  <td class="text-center">
                    {{ item.status == 1 ? 'Active' : 'Pending' }}
                  </td>
                  <td class="text-center">
                    <v-btn
                      @click="$router.push({ name: 'PostCategoryEdit', params: { id: item.id } })"
                      color="#56ca00"
                      icon
                    >
                      <v-icon>
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                    <v-btn @click="dltItem(item.id, i)" color="#ff4c51" icon>
                      <v-icon>
                        {{ icons.mdiTrashCanOutline }}
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div id="app" v-if="!load">
            <pagination
              :totalPages="paginate.total_pages"
              :perPage="paginate.per_page"
              :currentPage="paginate.current_page"
              @pagechanged="onPageChange"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiMagnify, mdiPencilOutline, mdiTrashCanOutline, mdiPlusCircleOutline } from '@mdi/js'
import Pagination from '../../components/Pagination.vue'
export default {
  name: 'PostCategoryList',
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiPlusCircleOutline,
      },
    }
  },
  data() {
    return {
      items: [],
      paginate: {},
      search: null,
      load: true,
    }
  },
  components: {
    Pagination,
  },
  created() {
    this.init(1)
  },
  methods: {
    init(page) {
      var url = 'post-categories?page=' + page
      if (this.search) {
        url += '&search=' + this.search
      }
      this.$api.get(url).then(response => {
        this.items = response.data.post_categories.data
        this.paginate = response.data.post_categories.pagination
        this.items.forEach(val => {
          if (val.image != null) {
            val.image = process.env.VUE_APP_MEDIA_URL + val.image
          }
        })
        this.load = false
      })
    },
    dltItem(id, i) {
      if (confirm('Are you sure?')) {
        this.$api.post('post-categories/' + id, { _method: 'DELETE' }).then(response => {
          this.$toast.info(response.message)
          this.items.splice(i, 1)
        })
      }
    },
    onPageChange(page) {
      this.init(page)
    },
  },
}
</script>
